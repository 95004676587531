import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["content"]

  expand() {
    this.contentTarget.classList.remove("d-none");
  }

  hide() {
    this.contentTarget.classList.add("d-none");
  }
}