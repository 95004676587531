import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="sticky"
export default class extends Controller {
  static targets = ["info", "spacing"]

  onScroll(e) {
    let docBody = document.documentElement || document.body.parentNode || document.body;
    let hasOffset = window.pageYOffset !== undefined;
    let scrollTop = hasOffset ? window.pageYOffset : docBody.scrollTop;
    
    if (scrollTop > 60 || self.pageYOffset > 60) {
      this.infoTarget.classList.add('sticky');
      this.infoTarget.classList.add('navbar-white');
    } else if (scrollTop < 60 || self.pageYOffset < 60) {
      this.infoTarget.classList.remove('sticky');
    }
  }
}
