import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="lightbox"
export default class extends Controller {
  static targets = ["mainImage"];

  toggleMain() {
    // toggle main image

    let selectedImage = event.target
    this.mainImageTarget.innerHTML = selectedImage.outerHTML
    this.mainImageTarget.firstChild.classList.toggle("image-sm")
    this.mainImageTarget.firstChild.classList.toggle("image-main")
  }
}
