import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="modal"
export default class extends Controller {
  static targets = ["modal", "modalAll"];

  open() {
    // open specific modal

    let photoId = event.target.parentElement.dataset.photoId
    let modal = this.modalTargets.find((target) => target.dataset.modalId == photoId)
    this.addOpenStyle(modal)
  }

  close() {
    // close specific modal

    let btnId = event.target.dataset.modalId
    let modal = this.modalTargets.find((target) => target.dataset.modalId == btnId)
    this.addCloseStyle(modal)
  }

  openAll() {
    // open modal with all images

    this.addOpenStyle(this.modalAllTarget)
  }

  closeAll() {
    // close modal with all images

    this.addCloseStyle(this.modalAllTarget)
  }

  addOpenStyle(modal) {
    modal.classList.add("modal-open");
    modal.setAttribute("style", "display: block;");
    modal.classList.add("show");
    document.body.innerHTML += '<div class="modal-backdrop fade show"></div>';
  }

  addCloseStyle(modal) {
    modal.classList.remove("modal-open");
    modal.setAttribute("style", "display: none;");
    modal.classList.remove("show");
    document.getElementsByClassName("modal-backdrop")[0].remove();
  }
}
