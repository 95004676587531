import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="previewer"
export default class extends Controller {
  static targets = ["input", "wrapper"]

  preview() {
    let input = this.inputTarget
    let files = input.files
    let wrapper = this.wrapperTarget
    wrapper.innerHTML = '<i class="fa-solid fa-circle-notch fa-spin"></i>'
    
    for (var i = 0; i < files.length; i++) {
      let reader = new FileReader()
      wrapper.innerHTML = ''
      reader.onload = function () {
        let image = document.createElement("img")
        let wrapper = document.querySelector('#js-wrapper')
        wrapper.appendChild(image)
        image.style.height = '100px'
        image.src = reader.result
      }
      reader.readAsDataURL(files[i])
    }
  }

}
