import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="sidebar"
export default class extends Controller {
  static targets = ['sidebar', 'button'];

  toggle() {
    this.sidebarTarget.classList.toggle('active');
    this.buttonTarget.classList.toggle('active');
  }
}
